import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkText } from "../../templates/LinkText";

const WashingMachine = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <h3>{t("Applications - WashingMachine - Title - 1")}</h3>
              <p>{t("Applications - WashingMachine - Text - 1")}</p>

              <h3>{t("Applications - WashingMachine - Title - 2")}</h3>
              <p>{t("Applications - WashingMachine - Text - 2")}</p>
              <h3>{t("Applications - WashingMachine - Title - 3")}</h3>
              <p>{t("Applications - WashingMachine - Text - 3")}</p>
              <p>{t("Applications - WashingMachine - Text - 4")}</p>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WashingMachine;
